import React from 'react';
import {
  Box,
  Button,
  Card,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSnackbar } from 'notistack';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Benefit,
  CRSubtitle,
  DoctorCoverageList,
  DrugCoverageList,
  SimplePlanDetail as SimplePlanDetailForeign,
  SimplePlanDetailProps
} from '@coverright/ui/marketplaces';
import { colors } from '@coverright/ui/themes';
import { CRPreloader } from '@coverright/ui/shared';
import { Tooltip } from '@coverright/ui/inputs';
import { ComparisonContext } from '../../ComparisonContext';
import ComparisonPanel from '../plan-comparison/components/ComparisonPanel';
import { ListPlanDetail as ListPlanDetailForeign, ListPlanDetailProps } from '../../components/ListPlanDetail';
import { JsxPlanDetail as JsxPlanDetailForeign, JsxPlanDetailProps } from '../../components/JsxPlanDetail';
import { planStyles } from '../plan-list/components/PlanCard';
import { planFiles } from '../../utils/utils';
import OptionalCollapse from '../../components/OptionalCollapse';
import paths from '../../../config/router-paths';
import { PlanSectionTitle } from './components/PlanSectionTitle';
import HeaderCard from './components/HeaderCard';
import PlanDetailsContent from './components/PlanDetailsContent';
import {
  GetDefaultPlansYear,
  getGapTooltipByTier,
  getInitialTooltipByTier,
  GetNextPlansYear,
  getQuoteId
} from '@coverright/utils';
import { PlanWithTiers, useMedicarePlan } from '@coverright/data-access/medicare';
import { DrugTierOutput, PlanYear } from '@coverright/data-access/types/medicare';
import { QuoteContext } from '@coverright/shared/contexts';
import { getDeductible } from '@coverright/utils';
import { useParams } from 'react-router';
import { MaDrugCostBreakdownModal } from '@coverright/widgets';

const Element = Scroll.Element;
const Link = Scroll.Link;

const SimplePlanDetail = (props: SimplePlanDetailProps) => (
  <SimplePlanDetailForeign
    {...props}
    titleClassName={'fs-12'}
    imgClassName={'w-15 h-15'}
  />
);

const ListPlanDetail = (props: ListPlanDetailProps) => (
  <ListPlanDetailForeign
    {...props}
    titleClassName={'fs-12'}
    imgClassName={'w-15 h-15'}
  />
);

const JsxPlanDetail = (props: JsxPlanDetailProps) => (
  <JsxPlanDetailForeign {...props} titleClassName={'fs-12'} />
);

const styles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: colors.custom.green.variant2,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 400,
    },
    headerCard: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '8px 0 9px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    headerCardFixed: {
      display: 'flex',
      position: 'fixed',
      top: 0,
      height: 117,
      width: '100%',
      padding: 15,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '5px 0 24px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    headerCell: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '17.5px',
      color: colors.text.primary,
      background: 'rgba(215, 229, 250, 0.2)',
      padding: '10px 15px',
      minWidth: '115px',
    },
    tableRow: {
      '& td': {
        whiteSpace: 'nowrap',
        color: colors.text.primary,
        fontSize: 12,
        padding: 20,
        borderBottom: '1px solid #E8ECED',
        textAlign: 'left',
      },
      '& td:first-child': {
        fontWeight: 700,
      },
    },
    headerRow: {
      '& td': {
        padding: '10px 5px 10px 15px',
        borderBottom: '1px solid #E8ECED',
        textAlign: 'left',
      },
    },
    enrollSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '19px 19px 16px 16px',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        maxWidth: 195,
      },
    },
    sectionWrapper: {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.up('md')]: {
        marginLeft: 10,
        marginRight: 10,
      },
    },
    iconButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'flex-start',
      '& div': {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      },
    },
    checkbox: {
      flex: 'initial',
    },
    title: {
      flex: 1,
    },
    drugSubtitle: {
      fontSize: 14,
      color: colors.text.primary,
    },
    drugSubtitleWrapper: {
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: '#F7FAFE!important',
      padding: '16px 5px 16px 20px!important',
    },
    titleWrapper: {
      [theme.breakpoints.down('sm')]: {
        padding: '5px 16px!important',
      },
    },
  })
);

export interface PlanViewProps {
  zip: string;
  countyName: string;
  plan: PlanWithTiers;
  setModalOpen: (value: boolean) => void;
  setEmailOpen: (value: boolean) => void;
}

export const PlanView = (props: PlanViewProps) => {
  const { zip, plan, setModalOpen, countyName } = props;
  const { preferredDrugs, preferredPharmacies } =
    React.useContext(QuoteContext);
  const [expandedState, setExpandedState] = React.useState<{
    [key: string]: boolean;
  }>({});
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleExpanded = (key: string) => {
    const newValue =
      expandedState[key] === undefined ? false : !expandedState[key];
    setExpandedState({
      ...expandedState,
      [key]: newValue,
    });
  };

  const navigate = useNavigate();
  const ownClasses = styles();
  const classes = planStyles();

  const getExpandedValue = (key: string): boolean =>
    typeof expandedState[key] === 'undefined' ? true : expandedState[key];

  const files = React.useMemo(() => {
    return planFiles(plan?.planFiles, plan.planYear);
  }, [plan]);

  return (
    <Box justifyContent={'center'}>
      <HeaderCard {...props} />

      <div className={'h-30'} />

      <Element name={'Key Plan Benefits Overview'}>
        <PlanSectionTitle title={'Key Plan Benefits Overview'} />

        <Card className={`${ownClasses.card} pb-5`}>
          <Grid container direction={'column'}>
            <Element name={'Estimated Monthly Cost Summary'}></Element>
            <CRSubtitle
              title={'Estimated Monthly Cost Summary'}
              wrapperClassName={ownClasses.titleWrapper}
              expanded={
                phone ? getExpandedValue('monthlyCostSummary') : undefined
              }
              onToggleClick={() => toggleExpanded('monthlyCostSummary')}
              icon={'/assets/img/expenses.svg'}
            />

            <OptionalCollapse
              inCollapse={phone}
              in={getExpandedValue('monthlyCostSummary')}
            >
              <Box className={ownClasses.sectionWrapper}>
                <SimplePlanDetail
                  title={'Monthly plan premium'}
                  sx={{ mt: '15px' }}
                  tooltip={
                    'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'
                  }
                  titleClassName={'fs-12'}
                  content={plan.monthlyPremium}
                  thin={!phone}
                  hideDivider
                />
                <SimplePlanDetail
                  title={'Estimated drug costs'}
                  sx={{ my: '15px' }}
                  tooltip={
                    'Estimated monthly drug cost includes any annual out-of-pocket cost sharing expenses that you must pay for the medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the individual drug tier and the drug coverage breakdown as outlined in the plan details. The retail reference price (where applicable) for your drugs is based on average drug prices in the plan’s local area. Estimated monthly drug costs can only be calculated if you have entered medication information.'
                  }
                  content={
                    <Box
                      display={'flex'}
                      flexDirection={phone ? 'column' : 'row'}
                      alignItems={phone ? 'flex-start' : 'center'}
                    >
                      <Typography color={'textPrimary'} className={'fs-12'}>
                        {plan.drugsCost}
                      </Typography>
                      <div className={'w-40 h-5'} />
                      {!!preferredDrugs?.length && !plan.missingFormularyInAep && (
                        <Button
                          variant={'outlined'}
                          size={'small'}
                          sx={{ px: '24px!important', ml: { xs: -3, md: 0 } }}
                          onClick={() => setModalOpen(true)}
                        >
                          See monthly drug cost
                        </Button>
                      )}
                    </Box>
                  }
                  thin={!phone}
                  hideDivider
                />
                <SimplePlanDetail
                  title={'Estimated doctor costs'}
                  tooltip={
                    'Estimated doctor costs are calculated based on your estimated number of Primary Care Physician and Specialist visits per year, multiplied by the copays or coinsurance for in-network providers.'
                  }
                  content={plan.doctorsCost}
                  thin={!phone}
                />

                <SimplePlanDetail
                  title={'Estimated average total cost per month'}
                  tooltip={
                    'This is the sum of monthly premium, estimated monthly doctor costs and estimated monthly drug costs.'
                  }
                  content={
                    <Typography
                      color={'textPrimary'}
                      sx={{ fontWeight: { md: 700 } }}
                      className={'fs-12'}
                    >
                      {plan.monthlyCost}
                    </Typography>
                  }
                />
                {!!plan.optionalSupplementalBenefitsItems?.length && (
                  <ListPlanDetail
                    title={'Optional supplemental benefits'}
                    tooltip={
                      'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'
                    }
                    content={
                      plan.planYear === PlanYear.Year2023
                        ? [
                            'This plan has Optional Supplemental Benefits that you can enroll in for an additional premium. Please refer to the Summary of Benefits for the plan or speak to your dedicated advisor for more details.',
                          ]
                        : plan.optionalSupplementalBenefitsItems
                    }
                    thin={!phone}
                  />
                )}
                <SimplePlanDetail
                  sx={{ my: '15px' }}
                  title={<i>plus Medicare Part B Premium</i>}
                  tooltip={
                    "The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you're enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income."
                  }
                  content={
                    <i
                      className={'fs-12'}
                      style={{ color: colors.text.primary }}
                    >
                      {getDeductible(
                        'standardPartBMonthlyPremium',
                        plan.planYear
                      )}
                    </i>
                  }
                  thin={!phone}
                  hideDivider
                />
              </Box>
            </OptionalCollapse>

            <Element name={'Your Healthcare Selections'}></Element>
            <div className={'h-8'} />
            <CRSubtitle
              title={'Your Healthcare Selections'}
              wrapperClassName={ownClasses.titleWrapper}
              expanded={
                phone ? getExpandedValue('healthcareSelections') : undefined
              }
              onToggleClick={() => toggleExpanded('healthcareSelections')}
              icon={'/assets/img/healthcare.svg'}
            />

            <OptionalCollapse
              inCollapse={phone}
              in={getExpandedValue('healthcareSelections')}
            >
              <Box className={ownClasses.sectionWrapper}>
                <JsxPlanDetail
                  title={<strong className={'card-title'}>Your doctors</strong>}
                  className={'mv-16'}
                  content={
                    <div>
                      <DoctorCoverageList
                        labelClassName={'text-primary'}
                        hideTitle
                        isPPO={plan.planType === 'PPO'}
                        coverage={plan?.doctorsCoverage}
                      />
                      <div className={`h-8`} />
                      <Button
                        className={'w-140'}
                        variant={'outlined'}
                        size={'small'}
                        sx={{ px: '24px!important' }}
                        onClick={() =>
                          navigate(
                            paths.editPreferences + zip + '/' + countyName
                          )
                        }
                      >
                        {plan?.doctorsCoverage?.length ? 'Edit' : 'Add'} doctors
                      </Button>
                    </div>
                  }
                />
                <Divider />
                <JsxPlanDetail
                  title={<strong className={'card-title'}>Your drugs:</strong>}
                  className={'mv-16'}
                  content={
                    <div>
                      {!plan.missingFormularyInAep && (
                        <>
                          <DrugCoverageList
                            labelClassName={'text-primary mb-8'}
                            hideTitle
                            coverage={plan.drugsCoverage}
                          />
                          <div className={`h-8`} />
                          <Button
                            className={'w-140'}
                            variant={'outlined'}
                            size={'small'}
                            sx={{ px: '24px!important' }}
                            onClick={() =>
                              navigate(
                                paths.editPreferences +
                                  zip +
                                  '/' +
                                  countyName +
                                  '/drugs'
                              )
                            }
                          >
                            {plan?.drugsCoverage?.length ? 'Edit' : 'Add'} drugs
                          </Button>
                        </>
                      )}
                      {plan.missingFormularyInAep && (
                        <Typography
                          className={'fs-12 card-title'}
                          color={'textPrimary'}
                        >
                          Speak to an Agent for 2023 drug details for this plan
                        </Typography>
                      )}
                    </div>
                  }
                />
                <Divider />
                <JsxPlanDetail
                  title={
                    <strong className={'card-title'}>Your pharmacy:</strong>
                  }
                  className={'mv-16'}
                  content={
                    <div>
                      {preferredPharmacies?.length ? (
                        <Benefit
                          label={preferredPharmacies[0].name}
                          value={true}
                          labelClass={'regular text-primary'}
                        />
                      ) : (
                        <Typography
                          className={'fs-12 card-title'}
                          color={'textPrimary'}
                        >
                          You haven’t added a pharmacy
                        </Typography>
                      )}
                      <div className={'h-8'} />
                      <Button
                        className={'w-140'}
                        variant={'outlined'}
                        size={'small'}
                        sx={{ px: '24px!important' }}
                        onClick={() =>
                          navigate(
                            paths.editPreferences +
                              zip +
                              '/' +
                              countyName +
                              '/pharmacy'
                          )
                        }
                      >
                        {preferredPharmacies?.length ? 'Edit' : 'Add'} pharmacy
                      </Button>
                    </div>
                  }
                />
              </Box>
            </OptionalCollapse>

            <Element name={'PlanCoverageOverview'}></Element>
            <div className={'h-8'} />
            <CRSubtitle
              title={'Plan Highlights'}
              wrapperClassName={ownClasses.titleWrapper}
              expanded={phone ? getExpandedValue('planHighlights') : undefined}
              onToggleClick={() => toggleExpanded('planHighlights')}
              icon={'/assets/img/medical-record-1.svg'}
            />
            <OptionalCollapse
              inCollapse={phone}
              in={getExpandedValue('planHighlights')}
            >
              <Box className={ownClasses.sectionWrapper}>
                <SimplePlanDetail
                  sx={{ mt: '15px' }}
                  title={'Plan Type'}
                  tooltip={
                    'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'
                  }
                  content={`Medicare Advantage (${plan.planType})`}
                />
                {!!plan.partBGiveBack && (
                  <SimplePlanDetail
                    title={'Part B Giveback'}
                    tooltip={
                      'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'
                    }
                    content={
                      <Benefit
                        label={`${plan.partBAmount}`}
                        value={!!plan.partBGiveBack}
                      />
                    }
                  />
                )}
                <SimplePlanDetail
                  title={'Annual In-Network Deductible'}
                  tooltip={
                    'The amount you must pay out of pocket for your health care from in-network medical services before your coverage begins to pay. '
                  }
                  content={plan.inNetworkDeductableAmount}
                  emptyString={'This plan does not have a deductible.'}
                />
                <ListPlanDetail
                  title={'Out-of-Pocket Maximum'}
                  tooltip={
                    'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'
                  }
                  content={plan?.outOfPocketMaximum}
                />

                <ListPlanDetail
                  title={'Primary Doctor Visit'}
                  tooltip={
                    'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'
                  }
                  content={plan?.primaryCarePhysician}
                />
                <ListPlanDetail
                  title={'Specialist Visit'}
                  tooltip={
                    'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'
                  }
                  content={plan?.physicianSpecialist}
                />
                <ListPlanDetail
                  title={'Inpatient Hospital Care'}
                  tooltip={
                    'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'
                  }
                  content={plan?.hospitalBenefits}
                />

                {!!plan?.drugDetails && (
                  <>
                    <Element name={'DrugCoverage'}></Element>

                    <SimplePlanDetail
                      title={'Prescription Drug Coverage'}
                      tooltip={
                        'Part D (Prescription drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'
                      }
                      content={
                        <Benefit
                          value={plan?.extraBenefits.drugCoverage}
                          label={''}
                        />
                      }
                    />
                    {(plan.drugDetails.mrxAltDedAmount ||
                      plan.drugDetails.mrxAltNoDedTier) && (
                      <ListPlanDetail
                        title={'Prescription Drug Deductible'}
                        tooltip={
                          'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '
                        }
                        content={[
                          plan?.drugDetails.mrxAltDedAmount,
                          plan?.drugDetails.mrxAltNoDedTier,
                        ]}
                      />
                    )}
                  </>
                )}
                <SimplePlanDetail
                  title={'Dental Services'}
                  tooltip={
                    'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '
                  }
                  content={
                    <Link
                      to={'Extra Benefits'}
                      smooth={true}
                      spy={true}
                      duration={200}
                    >
                      <Benefit
                        labelClass={ownClasses.label}
                        value={(plan?.dental?.benefits?.length ?? 0) > 0}
                        label={
                          (plan?.dental?.benefits?.length ?? 0) > 0
                            ? 'View details'
                            : ''
                        }
                      />
                    </Link>
                  }
                />
                <SimplePlanDetail
                  title={'Vision Services'}
                  tooltip={
                    'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'
                  }
                  content={
                    <Link
                      to={'Extra Benefits'}
                      smooth={true}
                      spy={true}
                      duration={200}
                    >
                      <Benefit
                        labelClass={ownClasses.label}
                        value={(plan?.vision?.benefits?.length ?? 0) > 0}
                        label={
                          (plan?.vision?.benefits?.length ?? 0) > 0
                            ? 'View details'
                            : ''
                        }
                      />
                    </Link>
                  }
                />
                <SimplePlanDetail
                  title={'Hearing Services'}
                  tooltip={
                    'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'
                  }
                  content={
                    <Link
                      to={'Extra Benefits'}
                      smooth={true}
                      spy={true}
                      duration={200}
                    >
                      <Benefit
                        labelClass={ownClasses.label}
                        value={(plan?.hearing?.benefits?.length ?? 0) > 0}
                        label={
                          (plan?.hearing?.benefits?.length ?? 0) > 0
                            ? 'View details'
                            : ''
                        }
                      />
                    </Link>
                  }
                />
                <SimplePlanDetail
                  title={'Fitness Benefits'}
                  tooltip={
                    'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'
                  }
                  content={
                    <Link
                      to={'Extra Benefits'}
                      smooth={true}
                      spy={true}
                      duration={200}
                    >
                      <Benefit
                        labelClass={ownClasses.label}
                        value={plan.extraBenefits.fitness}
                        label={plan.extraBenefits.fitness ? 'View details' : ''}
                      />
                    </Link>
                  }
                />
                <SimplePlanDetail
                  title={'Insulin Savings'}
                  tooltip={
                    "Medicare Advantage plans that offer Insulin Savings are part of Medicare's Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit."
                  }
                  content={
                    <Link
                      to={'Extra Benefits'}
                      smooth={true}
                      spy={true}
                      duration={200}
                    >
                      <Benefit
                        labelClass={ownClasses.label}
                        value={plan.extraBenefits.insulinSavings}
                        label={
                          plan.extraBenefits.insulinSavings
                            ? 'View details'
                            : ''
                        }
                      />
                    </Link>
                  }
                />
                <SimplePlanDetail
                  title={'Over-the-counter Allowance'}
                  tooltip={
                    'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'
                  }
                  content={
                    <Link
                      to={'Extra Benefits'}
                      smooth={true}
                      spy={true}
                      duration={200}
                    >
                      <Benefit
                        labelClass={ownClasses.label}
                        value={plan?.oTC.length > 0}
                        label={plan?.oTC.length > 0 ? 'View details' : ''}
                      />
                    </Link>
                  }
                />
                <SimplePlanDetail
                  title={'Telehealth Services'}
                  tooltip={
                    'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'
                  }
                  content={
                    <Link
                      to={'Extra Benefits'}
                      smooth={true}
                      spy={true}
                      duration={200}
                    >
                      <Benefit
                        labelClass={ownClasses.label}
                        value={plan.extraBenefits.telehealth}
                        label={
                          plan.extraBenefits.telehealth ? 'View details' : ''
                        }
                      />
                    </Link>
                  }
                />
                <SimplePlanDetail
                  title={'Transportation Services'}
                  hideDivider
                  sx={{ mb: 3 }}
                  tooltip={
                    "Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor's appointments, pharmacies, or fitness centers."
                  }
                  content={
                    <Link
                      to={'Extra Benefits'}
                      smooth={true}
                      spy={true}
                      duration={200}
                    >
                      <Benefit
                        labelClass={ownClasses.label}
                        value={plan?.transportation.length > 0}
                        label={
                          plan?.transportation.length > 0 ? 'View details' : ''
                        }
                      />
                    </Link>
                  }
                />
              </Box>
            </OptionalCollapse>
          </Grid>
        </Card>
      </Element>

      {!!plan?.drugDetails && !!plan.extraBenefits.drugCoverage && (
        <Element name={'Drug Coverage & Costs'}>
          <div className={'h-30'} />
          <DrugDetails
            plan={plan}
            getExpandedValue={getExpandedValue}
            setExpandedState={setExpandedState}
          />
        </Element>
      )}

      <div className={'h-30'} />

      <Element name={'Medical & Hospital Coverage'}>
        <PlanSectionTitle title={'Medical & Hospital Coverage'} />
        <Card className={ownClasses.card}>
          <Grid container direction={'column'}>
            <CRSubtitle
              title={'Your Doctor Costs'}
              wrapperClassName={ownClasses.titleWrapper}
              expanded={phone ? getExpandedValue('doctorCosts') : undefined}
              onToggleClick={() => toggleExpanded('doctorCosts')}
              icon={'/assets/img/doctor.svg'}
            />

            <OptionalCollapse
              inCollapse={phone}
              in={getExpandedValue('doctorCosts')}
            >
              <Box className={ownClasses.sectionWrapper}>
                <ListPlanDetail
                  title={'Primary Doctor Visit'}
                  tooltip={
                    'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'
                  }
                  content={plan?.primaryCarePhysician}
                />
                <ListPlanDetail
                  title={'Specialist Visit'}
                  tooltip={
                    'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'
                  }
                  content={plan?.physicianSpecialist}
                />
                <ListPlanDetail
                  title={'Preventive Care'}
                  hideDivider
                  tooltip={
                    'Typically, periodic health exams that occur on a regular basis for preventive purposes. These can include routine physicals or annual check-ups.'
                  }
                  content={plan?.preventiveCare}
                />
              </Box>
            </OptionalCollapse>

            <div className={'h-20'} />
            <CRSubtitle
              title={'Medical and Hospital Coverage Overview'}
              wrapperClassName={ownClasses.titleWrapper}
              expanded={
                phone ? getExpandedValue('hospitalCoverage') : undefined
              }
              onToggleClick={() => toggleExpanded('hospitalCoverage')}
              icon={'/assets/img/healthcare.svg'}
            />

            <OptionalCollapse
              inCollapse={phone}
              in={getExpandedValue('hospitalCoverage')}
            >
              <Box className={ownClasses.sectionWrapper}>
                <SimplePlanDetail
                  title={'Emergency Room'}
                  sx={{ mt: '15px' }}
                  tooltip={
                    "Typically, emergency room services include all services provided when a patient visits an emergency room for an emergency condition. An emergency condition is any medical situation that would lead most people to believe that failing to obtain immediate medical care could result in placing the patient's health at serious risk."
                  }
                  content={plan?.hospitalityDetails.emergencyRoom}
                />

                <SimplePlanDetail
                  title={'Urgently Needed Services'}
                  tooltip={
                    "Medical care that you get outside of your Medicare health plan's service area for a sudden illness or injury that needs attention but isn't life threatening. If it's not safe to wait until you get home to get care from a plan doctor, then the health plan must pay for the care."
                  }
                  content={plan?.hospitalityDetails.urgentServices}
                />

                <ListPlanDetail
                  title={'Ambulance Services'}
                  tooltip={
                    'Transport by ambulance to and from your home and a medical facility to receive medical care. Medicare covers a number of different types of ambulance services depending on the seriousness of your medical condition, or whether other methods of transportation could endanger your health.'
                  }
                  content={plan?.hospitalityDetails.ambulanceServices}
                />

                <ListPlanDetail
                  title={'Inpatient Hospital Care'}
                  tooltip={
                    'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'
                  }
                  content={plan?.hospitalBenefits}
                />

                <ListPlanDetail
                  title={'Skilled Nursing Facilities (SNF)'}
                  tooltip={
                    'A nursing facility with the staff and equipment to give skilled nursing care and, in most cases, skilled rehabilitative services and other related health services.'
                  }
                  content={plan?.skilledNursing}
                />

                <ListPlanDetail
                  title={'Outpatient Diagnostic Tests and Therapeutic Services'}
                  titleClassName={phone ? undefined : 'w-205'}
                  tooltip={
                    'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'
                  }
                  content={plan?.outpatientHospitality}
                />

                <ListPlanDetail
                  title={'Outpatient Hospital And Surgery'}
                  tooltip={
                    'Typically defined as any surgical procedure that does not require an overnight stay in a hospital.'
                  }
                  content={plan?.outpatientSurgery}
                />

                <ListPlanDetail
                  title={'Outpatient Rehabilitation Services'}
                  tooltip={
                    'Services to help you regain abilities, such as speech or walking, that have been impaired by an illness or injury. These services take place in an outpatient setting. These services are provided by nurses, as well as by physical, occupational and speech therapists. Examples include working with a physical therapist to help you walk or working with an occupational therapist to help you get dressed.'
                  }
                  content={plan?.outpatientRehabilitation}
                />

                <ListPlanDetail
                  title={'Outpatient Mental Health Care'}
                  tooltip={
                    'Typically for services provided by a mental health professional in an outpatient setting.'
                  }
                  content={plan?.mentalHealth}
                />

                <ListPlanDetail
                  title={'Home Health Care'}
                  hideDivider
                  tooltip={
                    'Health care services and supplies that a doctor decides you may receive in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'
                  }
                  content={plan?.homeHealth}
                />
              </Box>
            </OptionalCollapse>
          </Grid>
        </Card>
      </Element>

      <div className={'h-30'} />

      <Element name={'Extra Benefits'}>
        <PlanSectionTitle title={'Extra Benefits'} />
        <Card className={ownClasses.card}>
          <Grid container direction={'column'}>
            <CRSubtitle
              title={'Additional benefits'}
              wrapperClassName={ownClasses.titleWrapper}
              expanded={
                phone ? getExpandedValue('additionalBenefits') : undefined
              }
              onToggleClick={() => toggleExpanded('additionalBenefits')}
              icon={'/assets/img/teeth.svg'}
            />
            <OptionalCollapse
              inCollapse={phone}
              in={getExpandedValue('additionalBenefits')}
            >
              <Box className={ownClasses.sectionWrapper}>
                {!!plan.optionalSupplementalBenefitsItems?.length && (
                  <ListPlanDetail
                    title={'Optional supplemental benefits'}
                    tooltip={
                      'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'
                    }
                    content={
                      plan.planYear === PlanYear.Year2023
                        ? [
                            'This plan has Optional Supplemental Benefits that you can enroll in for an additional premium. Please refer to the Summary of Benefits for the plan or speak to your dedicated advisor for more details.',
                          ]
                        : plan.optionalSupplementalBenefitsItems
                    }
                    thin
                  />
                )}
                <ListPlanDetail
                  title={'Dental Services'}
                  tooltip={
                    'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '
                  }
                  content={plan?.dental?.benefits || []}
                />

                <ListPlanDetail
                  title={'Vision Services'}
                  tooltip={
                    'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'
                  }
                  content={plan?.vision?.benefits || []}
                />

                <ListPlanDetail
                  title={'Hearing Services'}
                  tooltip={
                    'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'
                  }
                  content={plan?.hearing?.benefits || []}
                />

                <ListPlanDetail
                  title={'Fitness Benefits'}
                  tooltip={
                    'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'
                  }
                  content={plan?.otherDefinedSupplemental.fitnessBenefit}
                />

                <SimplePlanDetail
                  title={'Insulin Savings'}
                  tooltip={
                    "Medicare Advantage plans that offer Insulin Savings are part of Medicare's Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit."
                  }
                  content={
                    <Benefit
                      value={plan.extraBenefits.insulinSavings}
                      label={''}
                    />
                  }
                />

                <ListPlanDetail
                  title={'Meals After Hospital Stay'}
                  tooltip={
                    'Healthy food or home deliveries for plan members post discharge from hospital or skilled nursing facility.'
                  }
                  content={plan?.meal}
                />

                <ListPlanDetail
                  title={'Over-the-Counter Allowance'}
                  tooltip={
                    'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'
                  }
                  content={plan?.oTC}
                />

                <ListPlanDetail
                  title={'Telehealth Services'}
                  tooltip={
                    'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'
                  }
                  content={plan?.telehealth}
                />

                <ListPlanDetail
                  title={'Transportation Services'}
                  hideDivider
                  tooltip={
                    "Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor's appointments, pharmacies, or fitness centers."
                  }
                  content={plan?.transportation}
                />
              </Box>
            </OptionalCollapse>
          </Grid>
        </Card>
      </Element>

      <div className={'h-30'} />

      <Element name={'Plan Brochures'}>
        <PlanSectionTitle title={'Plan Brochures'} />
        <Card className={ownClasses.card} sx={{ pb: 2 }}>
          <CRSubtitle
            title={'Plan and Medicare Brochures'}
            wrapperClassName={ownClasses.titleWrapper}
            expanded={phone ? getExpandedValue('planBrochures') : undefined}
            onToggleClick={() => toggleExpanded('planBrochures')}
            icon={'/assets/img/brochures.svg'}
          />
          <OptionalCollapse
            inCollapse={phone}
            in={getExpandedValue('planBrochures')}
          >
            <Box className={ownClasses.sectionWrapper}>
              <ListPlanDetail
                title={'Plan Brochures'}
                content={files.map((item) => (
                  <a
                    style={{ marginLeft: '-24px' }}
                    className={`${classes.link} underline flex flex-align-center`}
                    target={'_blank'}
                    href={item.url}
                  >
                    <img src={'/assets/img/download.svg'} className={'mr-8'} />
                    {item.label}
                  </a>
                ))}
              />
              <SimplePlanDetail
                title={'Medicare Brochure(s)'}
                sx={{ mb: 3 }}
                hideDivider
                content={
                  <a
                    className={`${classes.link} underline flex flex-align-center`}
                    style={{ marginLeft: '-24px' }}
                    target={'_blank'}
                    href={
                      'https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf'
                    }
                  >
                    <img src={'/assets/img/download.svg'} className={'mr-8'} />
                    Medicare & You Handbook (
                    {plan.planYear?.toString().replace('Year', '')})
                  </a>
                }
              />
            </Box>
          </OptionalCollapse>
        </Card>
      </Element>
    </Box>
  );
};

export default () => {
  const { zip, countyName, planId } = useParams<{
    zip: string;
    countyName: string;
    planId: string;
  }>();
  const { isInCompare } = React.useContext(ComparisonContext);
  const { preferredDrugs, planYear, id } = React.useContext(QuoteContext);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [getPlan, { data, loading, error }] = useMedicarePlan();
  const [plan, setPlan] = React.useState<PlanWithTiers>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!getQuoteId()) {
      const urlParams = new URLSearchParams(window.location.search);
      let year = GetDefaultPlansYear();
      if (urlParams.has('planYear')) {
        year = urlParams.get('planYear') as PlanYear;
      }
      getPlan({
        variables: {
          zip: zip!,
          bidId: planId!,
          quoteId: getQuoteId(),
          countyName: countyName!,
          planYear: year,
          fullYear: year === GetNextPlansYear(),
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      let year = planYear;
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('planYear')) {
        year = urlParams.get('planYear') as PlanYear;
      }
      year = year || GetDefaultPlansYear();
      getPlan({
        variables: {
          zip: zip!,
          bidId: planId!,
          quoteId: id,
          countyName: countyName!,
          planYear: year,
          fullYear: year === GetNextPlansYear(),
        },
      });
    }
  }, [planYear, id]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setPlan(data?.plan);
  }, [data]);

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar('Something wrong. Please try again later.', {
        variant: 'error',
      });
    }
  }, [error]);

  return (
    <Container maxWidth={'lg'} sx={{ mb: 30 }}>
      <style>
        {`
        @media (min-width: 600px) {
          .card-title {
            white-space: nowrap;
          }
        }
      `}
      </style>
      <Button
        startIcon={<img src={'/assets/img/arrow-left.svg'} />}
        onClick={() => navigate(`/plans/${zip}/${countyName}`)}
      >
        Back to plans
      </Button>

      <Typography
        sx={{
          fontSize: { xs: 20, sm: 32 },
          lineHeight: { xs: '25px', sm: '40px' },
        }}
      >
        <b>{plan?.planName}</b> Plan Details
      </Typography>

      <Box sx={{ display: 'flex', gap: 4, mt: 4 }}>
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          {!loading && <PlanDetailsContent plan={plan} />}
        </Box>
        <div>
          {plan?.bidId && (
            <MaDrugCostBreakdownModal
              bidId={plan.bidId}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              zip={zip!}
              countyName={countyName!}
              planYear={plan.planYear || GetDefaultPlansYear()}
              quoteId={getQuoteId()}
            />
          )}

          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            {loading && <CRPreloader />}
            {!loading && (
              <>
                <Box maxWidth={800}>
                  {!!plan && (
                    <PlanView
                      countyName={countyName!}
                      plan={plan}
                      zip={zip!}
                      setEmailOpen={setEmailOpen}
                      setModalOpen={setModalOpen}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        </div>

        {(plan && zip) && (
          <ComparisonPanel
            zip={zip}
            countyName={countyName!}
            forceHide={!isInCompare(plan)}
          />
        )}
      </Box>
    </Container>
  );
};

const SectionTitleH2Styles = {
  table: {
    background: 'white!important',
    borderSpacing: 0,
  },
  wrapper: {
    background: 'rgba(232,236,237,0.5)',
    borderRadius: '4px',
    mx: { xs: 1, sm: '10px' },
    width: 'auto',
    padding: { xs: '16px 16px 14px 20px', sm: '16px 16px 14px 24px' },
  },
  button: {
    background: 'rgba(2, 144, 148, 0.5)',
    width: 30,
    maxWidth: 30,
    height: 30,
    padding: '10px',
    marginTop: '5px',
    marginLeft: '5px',
    '&:hover': {
      background: 'rgba(2, 144, 148, 0.5)',
    },
    '& img': {
      transform: 'rotate(-180deg)',
      transition: 'all 0.3s',
      '&.open': {
        transform: 'rotate(0) !important',
      },
    },
  },
  title: {
    fontSize: { xs: 18, sm: 20 },
    fontWeight: 700,
  },
};

const SectionTitleH2 = ({ ...props }: TitleProps) => (
  <Box
    className={props.wrapperClassName}
    sx={SectionTitleH2Styles.wrapper}
    display={'flex'}
    flexDirection={'row'}
    alignItems={{ xs: 'start', md: 'center' }}
    justifyContent={'space-between'}
    width={1}
  >
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'start'}
    >
      <Typography sx={SectionTitleH2Styles.title}>{props.title}</Typography>
      {props.subtitle && (
        <Typography variant={'body2'} className={'medium'}>
          {props.subtitle}
        </Typography>
      )}
    </Box>
    <IconButton
      onClick={() => props.onToggle(!props.expanded)}
      sx={SectionTitleH2Styles.button}
    >
      <img
        className={`${props.expanded ? 'open' : ''}`}
        src={'/assets/img/arrow-white.svg'}
      />
    </IconButton>
  </Box>
);

type TitleProps = {
  title: string;
  subtitle?: string;
  expanded: boolean;
  onToggle: (value: boolean) => void;
  wrapperClassName?: string;
};

const DrugDetails = ({
  plan,
  getExpandedValue,
  setExpandedState,
}: {
  plan: PlanWithTiers;
  getExpandedValue: (key: string) => boolean;
  setExpandedState: any;
}) => {
  const ownClasses = styles();
  const classes = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const tiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.initialCoverageDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.initialCoverageDrugTiers;
    } else {
      return plan?.drugsTiers30Standard?.initialCoverageDrugTiers;
    }
  }, [plan]);

  const gapTiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.coverageGapDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.coverageGapDrugTiers;
    } else {
      return plan?.drugsTiers30Standard?.coverageGapDrugTiers;
    }
  }, [plan]);

  const InitialTable = () => (
    <table cellSpacing={0} className={'mh-10'}>
      <tbody>
        <tr className={ownClasses.headerRow}>
          <td></td>
          <td className={ownClasses.headerCell}>
            Preferred Retail Pharmacy cost-sharing (30 day supply)
          </td>
          <td className={ownClasses.headerCell}>
            Standard Retail Pharmacy cost-sharing (30 day supply)
          </td>
          <td className={ownClasses.headerCell}>
            Preferred Mail Order cost-sharing (90 day supply){' '}
          </td>
          <td className={ownClasses.headerCell}>
            Standard Mail Order cost-sharing (90 day supply)
          </td>
        </tr>
        {plan?.quoteDrugsTiers?.pharmacyName && (
          <tr className={ownClasses.tableRow}>
            <td>Your pharmacy</td>
            <td colSpan={4} className={'p-0 bg-body'}>
              <Benefit
                sx={{ width: 'fit-content' }}
                className={'p-20 bg-white'}
                label={plan?.quoteDrugsTiers?.pharmacyName}
                value={true}
                labelClass={'regular color-primary'}
              />
            </td>
          </tr>
        )}
        {tiers?.map((tier, i, array) => (
          <tr key={tier.tierName} className={ownClasses.tableRow}>
            <td>
              <div className={`flex`}>
                <Tooltip
                  placement={'top'}
                  arrow
                  title={getInitialTooltipByTier(tier.tier)}
                >
                  <div className={'flex flex-align-start'}>
                    <img
                      className={`mr-8 w-15 h-15`}
                      src={'/assets/img/info.svg'}
                    ></img>
                    <div className={`card-title bold`}>{tier.tierName}</div>
                  </div>
                </Tooltip>
              </div>
            </td>
            {[
              plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage,
              plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i].coverage,
              plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i].coverage,
              plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i].coverage,
            ].every(
              (v) =>
                v ===
                plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i]
                  .coverage
            ) ? (
              <td
                colSpan={4}
                style={{
                  textAlign: 'center',
                  borderBottom: i === array.length - 1 ? 'none' : undefined,
                }}
              >
                {
                  plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i]
                    .coverage
                }
              </td>
            ) : (
              <>
                <td
                  style={{
                    borderBottom: i === array.length - 1 ? 'none' : undefined,
                  }}
                >
                  {plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i]
                    .coverage || 'n.a.'}
                </td>
                <td
                  style={{
                    borderBottom: i === array.length - 1 ? 'none' : undefined,
                  }}
                >
                  {plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i]
                    .coverage || 'n.a.'}
                </td>
                <td
                  style={{
                    borderBottom: i === array.length - 1 ? 'none' : undefined,
                  }}
                >
                  {plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i]
                    .coverage || 'n.a.'}
                </td>
                <td
                  style={{
                    borderBottom: i === array.length - 1 ? 'none' : undefined,
                  }}
                >
                  {plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i]
                    .coverage || 'n.a.'}
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const InitialList = () => (
    <div>
      <CRSubtitle
        title={'Preferred Retail Pharmacy (30 day supply)'}
        titleClassName={ownClasses.drugSubtitle}
        wrapperClassName={ownClasses.drugSubtitleWrapper}
        sx={{ width: 'auto' }}
        arrowIcon={'/assets/img/arrow-top.svg'}
        toggleColor={'#C4C4C4'}
        expanded={phone ? getExpandedValue('preferredRetail30') : undefined}
        onToggleClick={(value) =>
          setExpandedState((prevState: any) => ({
            ...prevState,
            preferredRetail30: value,
          }))
        }
      />
      <Collapse in={getExpandedValue('preferredRetail30')} className={'ph-16'}>
        {plan?.quoteDrugsTiers?.pharmacyName && (
          <SimplePlanDetail
            title={'Your pharmacy'}
            content={
              <Benefit
                label={plan?.quoteDrugsTiers?.pharmacyName}
                value={true}
                labelClass={'regular color-primary'}
              />
            }
          />
        )}
        {tiers?.map((tier, i) => (
          <SimplePlanDetail
            title={tier.tierName}
            key={tier.tierName}
            content={
              plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i]
                .coverage || 'n.a.'
            }
            tooltip={getInitialTooltipByTier(tier.tier)}
          />
        ))}
      </Collapse>
      <CRSubtitle
        title={'Standard Retail Pharmacy (30 day supply)'}
        titleClassName={ownClasses.drugSubtitle}
        wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
        arrowIcon={'/assets/img/arrow-top.svg'}
        sx={{ width: 'auto' }}
        toggleColor={'#C4C4C4'}
        expanded={phone ? getExpandedValue('standardRetail30') : undefined}
        onToggleClick={(value) =>
          setExpandedState((prevState: any) => ({
            ...prevState,
            standardRetail30: value,
          }))
        }
      />
      <Collapse in={getExpandedValue('standardRetail30')} className={'ph-16'}>
        {tiers?.map((tier, i) => (
          <SimplePlanDetail
            title={tier.tierName}
            key={tier.tierName}
            content={
              plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i]
                .coverage || 'n.a.'
            }
            tooltip={getInitialTooltipByTier(tier.tier)}
          />
        ))}
      </Collapse>
      <CRSubtitle
        title={'Preferred Mail Order (90 day supply)'}
        sx={{ width: 'auto' }}
        titleClassName={ownClasses.drugSubtitle}
        wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
        arrowIcon={'/assets/img/arrow-top.svg'}
        toggleColor={'#C4C4C4'}
        expanded={phone ? getExpandedValue('preferredMail90') : undefined}
        onToggleClick={(value) =>
          setExpandedState((prevState: any) => ({
            ...prevState,
            preferredMail90: value,
          }))
        }
      />
      <Collapse in={getExpandedValue('preferredMail90')} className={'ph-16'}>
        {tiers?.map((tier, i) => (
          <SimplePlanDetail
            title={tier.tierName}
            key={tier.tierName}
            content={
              plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i]
                .coverage || 'n.a.'
            }
            tooltip={getInitialTooltipByTier(tier.tier)}
          />
        ))}
      </Collapse>
      <CRSubtitle
        title={'Standard Mail Order (90 day supply)'}
        sx={{ width: 'auto' }}
        titleClassName={ownClasses.drugSubtitle}
        wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
        arrowIcon={'/assets/img/arrow-top.svg'}
        toggleColor={'#C4C4C4'}
        expanded={phone ? getExpandedValue('standardMail90') : undefined}
        onToggleClick={(value) =>
          setExpandedState((prevState: any) => ({
            ...prevState,
            standardMail90: value,
          }))
        }
      />
      <Collapse in={getExpandedValue('standardMail90')} className={'ph-16'}>
        {tiers?.map((tier, i) => (
          <SimplePlanDetail
            title={tier.tierName}
            key={tier.tierName}
            content={
              plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i]
                .coverage || 'n.a.'
            }
            tooltip={getInitialTooltipByTier(tier.tier)}
          />
        ))}
      </Collapse>
    </div>
  );

  const GapTable = () => (
    <table className={'mh-10'}>
      <tbody>
        <tr className={ownClasses.headerRow}>
          <td></td>
          <td className={ownClasses.headerCell}>
            Preferred Retail Pharmacy cost-sharing (30 day supply)
          </td>
          <td className={ownClasses.headerCell}>
            Standard Retail Pharmacy cost-sharing (30 day supply)
          </td>
          <td className={ownClasses.headerCell}>
            Preferred Mail Order cost-sharing (90 day supply){' '}
          </td>
          <td className={ownClasses.headerCell}>
            Standard Mail Order cost-sharing (90 day supply)
          </td>
        </tr>
        {gapTiers?.map((tier, i) => (
          <tr key={tier.tierName} className={ownClasses.tableRow}>
            <td>
              <div className={`flex`}>
                <Tooltip
                  placement={'top'}
                  arrow
                  title={getGapTooltipByTier(tier.tier)}
                >
                  <div className={'flex flex-align-start'}>
                    <img
                      className={`mr-8 w-15 h-15`}
                      src={'/assets/img/info.svg'}
                    ></img>
                    <div className={`card-title bold`}>{tier.tierName}</div>
                  </div>
                </Tooltip>
              </div>
            </td>
            {[
              plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage,
              plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage,
              plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage,
              plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage,
            ].every(
              (v) =>
                v ===
                plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage
            ) ? (
              <td colSpan={4} style={{ textAlign: 'center' }}>
                {plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage}
              </td>
            ) : (
              <>
                <td>
                  {plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i]
                    .coverage || 'n.a.'}
                </td>
                <td>
                  {plan?.drugsTiers30Standard?.coverageGapDrugTiers[i]
                    .coverage || 'n.a.'}
                </td>
                <td>
                  {plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i]
                    .coverage || 'n.a.'}
                </td>
                <td>
                  {plan?.drugsTiers90Standard?.coverageGapDrugTiers[i]
                    .coverage || 'n.a.'}
                </td>
              </>
            )}
          </tr>
        ))}
        <tr className={ownClasses.tableRow}>
          <td style={{ borderBottom: 'none' }}>
            <Tooltip placement={'top'} arrow title={''}>
              <div className={'flex flex-align-start'}>
                <img
                  className={`mr-8 w-15 h-15`}
                  src={'/assets/img/info.svg'}
                ></img>
                <div className={`card-title bold`}>All other tiers</div>
              </div>
            </Tooltip>
          </td>
          <td colSpan={4} style={{ textAlign: 'center', borderBottom: 'none' }}>
            25% of retail price
          </td>
        </tr>
      </tbody>
    </table>
  );

  const GapList = () => (
    <div>
      <CRSubtitle
        title={'Preferred Retail Pharmacy (30 day supply)'}
        sx={{ width: 'auto' }}
        titleClassName={ownClasses.drugSubtitle}
        wrapperClassName={ownClasses.drugSubtitleWrapper}
        arrowIcon={'/assets/img/arrow-top.svg'}
        toggleColor={'#C4C4C4'}
        expanded={phone ? getExpandedValue('preferredGapRetail30') : undefined}
        onToggleClick={(value) =>
          setExpandedState((prevState: any) => ({
            ...prevState,
            preferredGapRetail30: value,
          }))
        }
      />
      <Collapse
        in={getExpandedValue('preferredGapRetail30')}
        className={'ph-16'}
      >
        {gapTiers?.map((tier, i) => (
          <SimplePlanDetail
            title={tier.tierName}
            key={tier.tierName}
            content={
              plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage ||
              'n.a.'
            }
            tooltip={getGapTooltipByTier(tier.tier)}
          />
        ))}
      </Collapse>
      <CRSubtitle
        title={'Standard Retail Pharmacy (30 day supply)'}
        sx={{ width: 'auto' }}
        titleClassName={ownClasses.drugSubtitle}
        wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
        arrowIcon={'/assets/img/arrow-top.svg'}
        toggleColor={'#C4C4C4'}
        expanded={phone ? getExpandedValue('standardGapRetail30') : undefined}
        onToggleClick={(value) =>
          setExpandedState((prevState: any) => ({
            ...prevState,
            standardGapRetail30: value,
          }))
        }
      />
      <Collapse
        in={getExpandedValue('standardGapRetail30')}
        className={'ph-16'}
      >
        {gapTiers?.map((tier, i) => (
          <SimplePlanDetail
            title={tier.tierName}
            key={tier.tierName}
            content={
              plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage ||
              'n.a.'
            }
            tooltip={getGapTooltipByTier(tier.tier)}
          />
        ))}
      </Collapse>
      <CRSubtitle
        title={'Preferred Mail Order (90 day supply)'}
        sx={{ width: 'auto' }}
        titleClassName={ownClasses.drugSubtitle}
        wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
        arrowIcon={'/assets/img/arrow-top.svg'}
        toggleColor={'#C4C4C4'}
        expanded={phone ? getExpandedValue('preferredGapMail90') : undefined}
        onToggleClick={(value) =>
          setExpandedState((prevState: any) => ({
            ...prevState,
            preferredGapMail90: value,
          }))
        }
      />
      <Collapse in={getExpandedValue('preferredGapMail90')} className={'ph-16'}>
        {gapTiers?.map((tier, i) => (
          <SimplePlanDetail
            title={tier.tierName}
            key={tier.tierName}
            content={
              plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage ||
              'n.a.'
            }
            tooltip={getGapTooltipByTier(tier.tier)}
          />
        ))}
      </Collapse>
      <CRSubtitle
        title={'Standard Mail Order (90 day supply)'}
        sx={{ width: 'auto' }}
        titleClassName={ownClasses.drugSubtitle}
        wrapperClassName={`mt-10 ${ownClasses.drugSubtitleWrapper}`}
        arrowIcon={'/assets/img/arrow-top.svg'}
        toggleColor={'#C4C4C4'}
        expanded={phone ? getExpandedValue('standardGapMail90') : undefined}
        onToggleClick={(value) =>
          setExpandedState((prevState: any) => ({
            ...prevState,
            standardGapMail90: value,
          }))
        }
      />
      <Collapse in={getExpandedValue('standardGapMail90')} className={'ph-16'}>
        {gapTiers?.map((tier, i) => (
          <SimplePlanDetail
            title={tier.tierName}
            key={tier.tierName}
            content={
              plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage ||
              'n.a.'
            }
            tooltip={getGapTooltipByTier(tier.tier)}
          />
        ))}
      </Collapse>

      <div className={'ph-16'}>
        <SimplePlanDetail
          title={'All other tiers'}
          content={'25% of retail price'}
          tooltip={''}
        />
      </div>
    </div>
  );

  return (
    <>
      <PlanSectionTitle title={'Drug Coverage & Costs'} />
      <Card className={ownClasses.card}>
        <Grid container direction={'column'}>
          <Grid item>
            <CRSubtitle
              title={'Prescription Drug Costs'}
              wrapperClassName={ownClasses.titleWrapper}
              expanded={getExpandedValue('prescriptionDrugCosts')}
              onToggleClick={(value) =>
                setExpandedState((prevState: any) => ({
                  ...prevState,
                  prescriptionDrugCosts: value,
                }))
              }
              icon={`/assets/img/pills.svg`}
            />
            <Collapse in={getExpandedValue('prescriptionDrugCosts')}>
              <div className={'h-16'} />
              <SectionTitleH2
                title={'Deductible phase'}
                subtitle={
                  'You pay the full retail price of your drugs up until you meet the deductible level (if applicable).'
                }
                onToggle={(value) =>
                  setExpandedState((prevState: any) => ({
                    ...prevState,
                    deductible: value,
                  }))
                }
                expanded={getExpandedValue('deductible')}
              />
              <Collapse in={getExpandedValue('deductible')}>
                <Box className={ownClasses.sectionWrapper}>
                  <ListPlanDetail
                    title={'Deductible amount'}
                    sx={{ mt: 2, ml: '54px' }}
                    hideDivider
                    tooltip={
                      'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '
                    }
                    content={[
                      plan.drugDetails?.mrxAltDedAmount as string,
                      plan.drugDetails?.mrxAltNoDedTier as string,
                    ]}
                  />
                </Box>
              </Collapse>

              {!!plan?.drugDetails?.initialCoverage?.length && (
                <>
                  <SectionTitleH2
                    title={'Initial Coverage Limit phase'}
                    subtitle={plan?.drugDetails?.initialCoverageDescription}
                    onToggle={(value) =>
                      setExpandedState((prevState: any) => ({
                        ...prevState,
                        initialCoverage: value,
                      }))
                    }
                    expanded={getExpandedValue('initialCoverage')}
                  />
                  <Collapse in={getExpandedValue('initialCoverage')}>
                    <div className={'h-16'} />
                    {phone ? <InitialList /> : <InitialTable />}
                  </Collapse>
                </>
              )}

              {plan.planYear === PlanYear.Year2024 && <>
                {phone && <div className={'h-24'} />}
                <SectionTitleH2
                  title={'Coverage Gap phase'}
                  subtitle={plan?.drugDetails?.coverageGapDescription}
                  onToggle={(value) =>
                    setExpandedState((prevState: any) => ({
                      ...prevState,
                      gapCoverage: value,
                    }))
                  }
                  expanded={getExpandedValue('gapCoverage')}
                />
                <Collapse in={getExpandedValue('gapCoverage')}>
                  <div className={'h-16'} />
                  {phone ? <GapList /> : <GapTable />}
                </Collapse>
              </>}

              {phone && <div className={'h-24'} />}
              <SectionTitleH2
                title={'Catastrophic Coverage phase'}
                subtitle={plan?.drugDetails?.catastrophicCoverageDescription}
                onToggle={(value) =>
                  setExpandedState((prevState: any) => ({
                    ...prevState,
                    catastrophicCoverage: value,
                  }))
                }
                expanded={getExpandedValue('catastrophicCoverage')}
              />
              <Collapse in={getExpandedValue('catastrophicCoverage')}>
                <Box className={ownClasses.sectionWrapper}>
                  {plan?.drugDetails?.catastrophicCoverage[0].items.map(
                    (item, i, array) => (
                      <ListPlanDetail
                        key={item.title}
                        title={item.title}
                        sx={{ ml: '54px' }}
                        hideDivider={i === array.length - 1}
                        tooltip={getGapTooltipByTier(
                          [
                            DrugTierOutput.Generic,
                            DrugTierOutput.NonPreferredBrand,
                          ][i]
                        )}
                        content={item.values}
                      />
                    )
                  )}
                </Box>
              </Collapse>
            </Collapse>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
